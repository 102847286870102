
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  computed,
  ref,
} from "vue";
import { Api } from "@/assets/Api";
import { ElMessage, ElMessageBox } from "element-plus";
import ChaKanTouSu from "./TouSu.vue";
//import RegisterTime from "./RegisterTime.vue";

interface Column {
  label: string;
  prop: string;
  search: boolean;
  regex: boolean;
  rules: [];
}
export default defineComponent({
  setup(props, { emit }) {
    let data = reactive<any>({
        dialogFormVisible: false,
        appPackage: '',
        ApplicationList:[],
        selectform: {
            appPackage: "",
        },
        updateForm:{
            AppPackage: '',
            AppName: '',
        },
        currentPage:1, // 初始页
        pageSize:10,  // 初始每页的数据
        dCurrentPage:1, // 初始页
        dPageSize:10,  // 初始每页的数据
    });
    const selectApp = () => { 
        data.ApplicationList = []
        console.log(data.selectform.appPackage);
        let applicationdata = {appPackage: data.selectform.appPackage};
        Api.Config.GetApplicationList(applicationdata).then((res: any) => {
        console.log("res");
        console.log(res);
        
        if (res.Code == 0) {  
            for(var i = 0; i < res.Data.length; i++){
                data.ApplicationList.push(res.Data[i]);
            }
        } 
      });
            // console.log(data.ApplicationList);
    };
    const showUpdateApp = (item: any) => {
        data.dialogFormVisible = true;
        data.updateForm.AppPackage = item.AppPackage
        data.updateForm.AppName = item.AppName
    }
    const updateApp = (item: any) => {
        ElMessageBox.confirm("确认要更新APP信息吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "",
      })
        .then(() => {
            let applicationdata = {AppPackage: data.updateForm.AppPackage , AppName: data.updateForm.AppName};
          Api.Config.UpdateApplication(applicationdata).then((res: any) => {
            if (res.Code == 0) {
              ElMessage.success("更新成功");
              data.dialogFormVisible = false;
              selectApp();
            } else {
              ElMessage.warning({
                showClose: true,
                duration: 10000,
                message: res.ResultMsg,
              });
            }
          });
        })
        .catch(() => {
          console.log("刷新");
        });
    };
    const handleSizeChange = (size: any) => {    
        data.pageSize = size;
        // console.log(data.pagesize)  //每页下拉显示数据
    };

    const handleCurrentChange = (currentPage: any) => {    
        data.currentPage = currentPage;
        console.log(data.currentPage)  //点击第几页
    };

    const dHandleSizeChange = (size: any) => {    
        data.dPageSize = size;
        // console.log(data.pagesize)  //每页下拉显示数据
    };

    const dHandleCurrentChange = (currentPage: any) => {    
        data.dCurrentPage = currentPage;
        // console.log(data.currentPage)  //点击第几页
    };
    // onMounted(() => {
    // });

    return {
      ...toRefs(data),
      selectApp,
      updateApp,
      showUpdateApp,
      handleSizeChange,
      handleCurrentChange,
      dHandleSizeChange,
      dHandleCurrentChange,
    };
  },
});
