import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-34dbf5a3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sketch_content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_form, { model: _ctx.RevenueBudgetForm }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, {
          label: "服务名称",
          "label-width": "120px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_select, {
              modelValue: _ctx.OperateName,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.OperateName) = $event)),
              placeholder: "请选择服务名称",
              onChange: _ctx.selectOperateChange,
              clearable: "",
              onClear: _ctx.clearOperateChange
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    key: item.value,
                    label: item.label,
                    value: item
                  }, null, 8, ["label", "value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue", "onChange", "onClear"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "套餐价格（元）",
          "label-width": "120px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.RevenueBudgetForm.PackagesFee,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.RevenueBudgetForm.PackagesFee) = $event)),
              autocomplete: "off"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "营销费用（元）",
          "label-width": "120px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.RevenueBudgetForm.MarketingFee,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.RevenueBudgetForm.MarketingFee) = $event)),
              autocomplete: "off"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "分成比例",
          "label-width": "120px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.RevenueBudgetForm.SharingRatio,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.RevenueBudgetForm.SharingRatio) = $event)),
              autocomplete: "off"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "坏账率",
          "label-width": "120px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.RevenueBudgetForm.BadDebtsRatio,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.RevenueBudgetForm.BadDebtsRatio) = $event)),
              autocomplete: "off"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "回款月份",
          "label-width": "120px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.RevenueBudgetForm.DelayedMonth,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.RevenueBudgetForm.DelayedMonth) = $event)),
              autocomplete: "off"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          type: "primary",
          onClick: _ctx.submitOperate
        }, {
          default: _withCtx(() => [
            _createTextVNode("提 交")
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createTextVNode(" （以营销10000用户为例计算） ")
      ]),
      _: 1
    }, 8, ["model"]),
    _createVNode(_component_el_table, {
      class: "RevenueBudget",
      ref: "table",
      data: _ctx.RevenueBudgetList,
      border: "",
      style: {"width":"100%"},
      "row-key": "Months",
      stripe: "",
      "header-cell-style": {'text-align':'center'},
      "cell-style": {'text-align':'center'}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          prop: "Months",
          label: "持续月份"
        }),
        _createVNode(_component_el_table_column, {
          prop: "Expenditure",
          label: "支出（元）"
        }),
        _createVNode(_component_el_table_column, {
          prop: "Income",
          label: "收入（元）"
        }),
        _createVNode(_component_el_table_column, {
          prop: "Profit",
          label: "营收（元）"
        })
      ]),
      _: 1
    }, 8, ["data"])
  ]))
}