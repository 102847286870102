
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import { Api } from "@/assets/Api";
import { ElMessage, ElMessageBox } from "element-plus";
import store from "@/store";
import StopTreatmentObject from "./StopTreatmentObject.vue";

interface Column {
  label: string;
  prop: string;
  search: boolean;
  regex: boolean;
  rules: [];
}
export default defineComponent({
  name: "ConfigStopTreatment",
  components: { StopTreatmentObject },
  setup() {
    let data = reactive<any>({
      init: false,
      data: {},
      option: {},
      infoOption: {},
      form: {
        regs: [],
      },
      page: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20],
        layout: "total, sizes, prev, pager, next",
      },
      query: {
        limit: 10,
        page: 1,
        where: {},
        sort: "-id",
      },
    });

    const searchChange = (params: any, done: any) => {
      console.log(params);
      for (let p in params) {
        const field: Column = data.option.column.find(
          (v: Column) => v.prop === p
        );
        if (field.regex) {
          params[p] = { $regex: params[p] };
        }
      }
      data.query.where = params;
      data.page.currentPage = 1;
      data.query.page = 1;
      data.query.limit = data.page.pageSize;
      getList();
      done();
    };

    const changePage = ({ pageSize, currentPage }: any) => {
      data.query.page = currentPage;
      data.query.limit = pageSize;
      getList();
    };

    const getList = () => {
      console.log(data.query);
      Api.Config.GetStopTreatmentList(data.query).then((res: any) => {
        console.log(res);
        data.data = {};
        if (res.Code == 0) {
          data.data = res.Data;
          data.page.total = res.Data.total;
          data.page.currentPage = res.Data.page;
          // data.data.data.forEach((element: any) => {
          //   element.hospitalid = element.hospitalid + "";
          // });
        } else {
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
        }
      });
    };

    const getOption = () => {
      Api.Config.GetStopTreatmentListOption().then((res: any) => {
        console.log(res);
        // const localHospital: string = store.getters.localHospital;
        // if (localHospital) {
        //   const jsonHospital = JSON.parse(localHospital);
        //   res.column.filter((c: any) => {
        //     if (c.prop == "hospital") c.dicData = jsonHospital;
        //   });
        // }
        data.option = res;
        data.init = true;
      });
    };

    const getInfoOption = () => {
      Api.Config.GetStopTreatmentListInfoOption().then((res: any) => {
        console.log("getInfoOption" + res);
        const allHospital: string = store.getters.allHospital;
        if (allHospital) {
          const jsonHospital = JSON.parse(allHospital);

          jsonHospital.forEach((p: any) => {
            p.children.forEach((c: any) => {
              c.children.forEach((h: any) => {
                h.value += "";
              });
            });
          });
          res.column.filter((c: any) => {
            if (c.prop == "hospital") c.dicData = jsonHospital;
          });
        }
        data.infoOption = res;
      });
    };

    const rowSave = (form: any, done: any, loading: any) => {
      // form.hospitalid = parseInt(form.hospitalid);
      //console.log(form);
      //form.regs = data.form.myregs;
      //done();
      var regs: any = [];
      form.regs.forEach((element: any) => {
        regs.push({
          hospital: parseInt(element.hospital),
          reg: element.reg,
        });
        //element.hospital = parseInt(element.hospital);
      });
      form.regs = regs;
      Api.Config.AddStopTreatment(form).then((res: any) => {
        if (res.Code == 0) {
          ElMessage.success("保存成功");
          getList();
          done();
        } else {
          loading();
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
        }
      });
    };

    const rowDelete = (row: any, index: any) => {
      ElMessageBox.confirm("删除后无法恢复，确定要删除此停诊信息吗？", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "",
      })
        .then(() => {
          Api.Config.DeleteStopTreatment(row.id).then((res: any) => {
            if (res.Code == 0) {
              ElMessage.success("删除成功");
              getList();
            } else {
              ElMessage.warning({
                showClose: true,
                duration: 10000,
                message: res.ResultMsg,
              });
            }
          });
        })
        .catch(() => {
          console.log("xx");
        });
    };

    const SetObjects = (objects: any) => {
      console.log(objects);
      data.form.regs = objects;
      console.log(data.form);
    };

    // const rowREGSave = (form: any, done: any, loading: any) => {
    //   if (!data.form.myregs) data.form.myregs = [];
    //   data.form.myregs.push({ hospital: form.hospital, reg: form.reg });
    //   done();
    // };

    // const rowREGDel = (form: any, index: any, done: any) => {
    //   done(form);
    // };

    onMounted(() => {
      getOption();
      getInfoOption();
    });

    return {
      ...toRefs(data),
      searchChange,
      changePage,
      rowSave,
      rowDelete,
      SetObjects,
    };
  },
});
