
import { defineComponent, reactive, toRefs, ref, computed } from "vue";
import { Api } from "@/assets/Api";
import { ElMessage } from "element-plus";
import type { FormInstance } from "element-plus";

export default defineComponent({
  name: "ConfigHospitalRules",
  props: ["dialogFormVisible"],
  emits: ["close"],
  components: {},
  setup(props, { emit }) {
    let data = reactive<any>({
      statusOptions: [
        {
          value: 0,
          label: "未确认",
        },
        {
          value: 1,
          label: "院方受理",
        },
        {
          value: 3,
          label: "完成提醒回访",
        },
      ],
      week: [
        {
          value: 0,
          label: "不限制",
        },
        {
          value: 1,
          label: "星期一",
        },
        {
          value: 2,
          label: "星期二",
        },
        {
          value: 3,
          label: "星期三",
        },
        {
          value: 4,
          label: "星期四",
        },
        {
          value: 5,
          label: "星期五",
        },
        {
          value: 6,
          label: "星期六",
        },
        {
          value: 7,
          label: "星期天",
        },
      ],
      rules: {
        tqdays: [
          {
            type: "number",
            required: true,
            message: "请输入正整数",
            trigger: "blur",
          },
        ],
        dayopentime: [
          {
            required: true,
            message: "请选择开始预约时间",
            trigger: ["blur", "change"],
          },
        ],
      },
      formRules: {
        status: 0,
        dayclosetime: "16:00",
        dayopentime: "",
        id: 0,
        orgid: "0",
        tqdays: 7,
        typeid: 1,
        weekclosedays: 0,
        weekclosetime: "16:00",
        weekstartcloseday: 5,
        HospitalId: "",
      },
      formLabelWidth: "140px",
      formRulesRef: ref<FormInstance>(),
    });

    const dialogVisible = computed({
      get() {
        return props.dialogFormVisible;
      },
      set() {
        emit("close");
      },
    });

    const getWeekclosedays = computed({
      get() {
        if (data.formRules.weekclosedays == 0) return 0;
        return (
          data.formRules.weekclosedays - (7 - data.formRules.weekstartcloseday)
        );
      },
      set(val: number) {
        if (val == 0) return (data.formRules.weekclosedays = 0);
        data.formRules.weekclosedays =
          7 - data.formRules.weekstartcloseday + val;
      },
    });

    const getTqdays = computed({
      get() {
        return data.formRules.tqdays * 1;
      },
      set(val: number) {
        data.formRules.tqdays = val * 1;
      },
    });

    const getRules = (HospitalID: string) => {
      Api.Config.GetHospitalRules(HospitalID).then((res: any) => {
        console.log(res);
        if (res.Code == 0) {
          if (res.Data) {
            data.formRules = res.Data;
          } else {
            data.formRules.id = 0;
            data.formRules.orgid = HospitalID + "";
          }
        } else {
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
          emit("close");
        }
      });
    };

    const saveRules = (formEl: FormInstance | undefined) => {
      if (data.formRules.tqdays < 1) {
        ElMessage.warning({
          showClose: true,
          duration: 10000,
          message: "提前预约天数需大于1",
        });
        return;
      }
      (formEl as FormInstance).validate((valid) => {
        if (valid) {
          console.log("submit!");
          console.log(data.formRules);
          if (data.formRules.id == 0) {
            Api.Config.AddRules(data.formRules).then((res: any) => {
              if (res.Code == 0) {
                ElMessage.success("保存成功");
                emit("close");
              } else {
                ElMessage.warning({
                  showClose: true,
                  duration: 10000,
                  message: res.ResultMsg,
                });
              }
            });
          } else {
            Api.Config.EditRules(data.formRules.id, data.formRules).then(
              (res: any) => {
                if (res.Code == 0) {
                  ElMessage.success("保存成功");
                  emit("close");
                } else {
                  ElMessage.warning({
                    showClose: true,
                    duration: 10000,
                    message: res.ResultMsg,
                  });
                }
              }
            );
          }
        } else {
          console.log("error submit!");
        }
      });

      // this.$refs.formRulesRef.validate(async (valid) => {
      //   if (!valid) return;
      //   console.info(this.formRules);
      //   this.$Api.AddRules(this.formRules, (res) => {
      //     if (res.code == 0) {
      //       this.onSearch();
      //       this.dialogFormVisible = false;
      //     }
      //   });
      // });
    };

    // onMounted(() => {
    //   console.log(props);
    //   getRules;
    // });

    return {
      ...toRefs(data),
      ...toRefs(props),
      dialogVisible,
      getWeekclosedays,
      getTqdays,
      getRules,
      saveRules,
    };
  },
});
