
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  computed,
  ref,
} from "vue";
import { Api } from "@/assets/Api";
import { ElMessage, ElMessageBox } from "element-plus";
import ChaKanTouSu from "./TouSu.vue";
//import RegisterTime from "./RegisterTime.vue";

interface Column {
  label: string;
  prop: string;
  search: boolean;
  regex: boolean;
  rules: [];
}
export default defineComponent({
  name: "ConfigTouSu",
  props: ["dialogFormVisible"],
  emits: ["close"],
  components: { ChaKanTouSu },
  setup(props, { emit }) {
    let data = reactive<any>({
      init: false,
      dialogFormVisible2: false,
      dialogFormVisible3: false,
      url: "",
      maps: new Map(),
      selectMarketingList:[],
      selectform: {
        Phone: "",
      },
    });
    const selectMarketing = (item: any) => {      
        if(data.selectform.Phone == ""){
            ElMessage.warning("请输入用户号码");
            return;
        }
        data.selectMarketingList = []
        Api.Config.getSelectMarketing(data.selectform.Phone).then((res: any) => {
        console.log("res");
        console.log(res);
        
        if (res.Code == 0) {  
            for(var i = 0; i < res.Data.length; i++){
                res.Data[i].CreatedAt = res.Data[i].CreatedAt.replace(/T/g, ' ').replace(/.[\d]{3}Z/, ' ').substring(0, 19);
                if(res.Data[i].Status == true){
                    res.Data[i].Status = "成功"
                }
                else if(res.Data[i].Status == false){
                    res.Data[i].Status = "失败"
                }
                data.selectMarketingList.push(res.Data[i]);
            }
        } 
      });
            console.log(data.selectMarketingList);
    };
    
    // onMounted(() => {
    // });

    return {
      ...toRefs(data),
      selectMarketing,
    };
  },
});
