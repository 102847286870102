import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_form, {
      inline: true,
      class: "selectdate"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              type: "primary",
              onClick: _ctx.showMarketingSwitching,
              icon: "el-icon-plus"
            }, {
              default: _withCtx(() => [
                _createTextVNode("新增互联网营销切换")
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_table, {
      ref: "table",
      data: _ctx.marketingSwitchingList,
      border: "",
      style: {"width":"100%"},
      height: "70vh",
      "row-key": "PackagesName",
      stripe: "",
      "header-cell-style": {'text-align':'center'},
      "cell-style": {'text-align':'center'}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          prop: "MarketingId",
          label: "原产品"
        }),
        _createVNode(_component_el_table_column, {
          prop: "SwitchingId",
          label: "切换产品"
        }),
        _createVNode(_component_el_table_column, {
          prop: "SwitchingAt",
          label: "切换时间"
        })
      ]),
      _: 1
    }, 8, ["data"]),
    _createVNode(_component_el_dialog, {
      title: "新增互联网营销切换",
      modelValue: _ctx.dialogFormVisible,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dialogFormVisible) = $event))
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_el_button, {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.dialogFormVisible = false))
          }, {
            default: _withCtx(() => [
              _createTextVNode("取 消")
            ]),
            _: 1
          }),
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: _ctx.addMarketingSwitching
          }, {
            default: _withCtx(() => [
              _createTextVNode("确 定")
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_form, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, { label: "产品" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: _ctx.SpName,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.SpName) = $event)),
                  placeholder: "请选择原产品名称",
                  onChange: _ctx.selectSpName
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.packageList, (item) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: item.PackageId,
                        label: item.PackageName,
                        value: item
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "onChange"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { label: "产品" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: _ctx.switchSpName,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.switchSpName) = $event)),
                  placeholder: "请选择切换产名称",
                  onChange: _ctx.selectSpName2
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.packageList, (item) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: item.PackageId,
                        label: item.PackageName,
                        value: item
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "onChange"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}