
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  computed,
  ref,
  getCurrentInstance ,
} from "vue";
import { Api } from "@/assets/Api";
import { ElMessage, ElMessageBox } from "element-plus";
import ChaKanTouSu from "./TouSu.vue";
import { nextTick } from 'vue';
//import RegisterTime from "./RegisterTime.vue";

interface Column {
  label: string;
  prop: string;
  search: boolean;
  regex: boolean;
  rules: [];
}
export default defineComponent({
  name: "ConfigTouSu",
  props: ["dialogFormVisible"],
  emits: ["close"],
  components: { ChaKanTouSu },
  setup(props, { emit }) {
    const datePickerVisible = ref(false);
    // const {proxy} = getCurrentInstance() as any
    // const instance = getCurrentInstance() as any;
    let data = reactive<any>({
      init: false,
      url: "",
      maps: new Map(),
      data: {},
      option: {},
      title: "",
      total: 0, // 总条目数
      marketingReportDayList:[],
      marketingReportMonthList:[],
      marketingReportAllList: [],
      SpName:"",
      SpValue: '',
      isSelect: 1,
      packageList: [],
      DateSelect: [],
      shortcuts: [
    {
        text: '今天',
        onClick:(picker: any)  =>  {
            // console.log("picker");
            data.DateSelect = []
            let start = new Date()
            let end = new Date()
            start.setHours(0, 0, 0)
            end.setHours(23, 59, 59)
            picker.emit('pick',data.DateSelect);
            const startyear = start.getFullYear();
            const startmonth = (start.getMonth() + 1).toString().padStart(2, '0');
            const startday = start.getDate().toString().padStart(2, '0');            
            const endyear = end.getFullYear();
            const endmonth = (end.getMonth() + 1).toString().padStart(2, '0');
            const endday = end.getDate().toString().padStart(2, '0');
            let startstr = startyear+"-"+startmonth+"-"+startday
            let endstr = endyear+"-"+endmonth+"-"+endday
            data.DateSelect.push(startstr)
            data.DateSelect.push(endstr)
        }
    },
    {
        text: '昨天',
        onClick(picker: any) {
            data.DateSelect = []
            let start = new Date()
            let end = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24)
            end.setTime(end.getTime() - 3600 * 1000 * 24)
            start.setHours(0, 0, 0)
            end.setHours(23, 59, 59)
            picker.emit('pick',data.DateSelect);
            const startyear = start.getFullYear();
            const startmonth = (start.getMonth() + 1).toString().padStart(2, '0');
            const startday = start.getDate().toString().padStart(2, '0');            
            const endyear = end.getFullYear();
            const endmonth = (end.getMonth() + 1).toString().padStart(2, '0');
            const endday = end.getDate().toString().padStart(2, '0');
            let startstr = startyear+"-"+startmonth+"-"+startday
            let endstr = endyear+"-"+endmonth+"-"+endday
            data.DateSelect.push(startstr)
            data.DateSelect.push(endstr)
            // picker.$emit('pick', [start, end]);
        }
    },
    {
        text: '近7天',
        onClick(picker: any) {
            data.DateSelect = []
            let start = new Date()
            let end = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            end.setTime(end.getTime() - 3600 * 1000 * 24)
            start.setHours(0, 0, 0)
            end.setHours(23, 59, 59)
            picker.emit('pick',data.DateSelect);
            const startyear = start.getFullYear();
            const startmonth = (start.getMonth() + 1).toString().padStart(2, '0');
            const startday = start.getDate().toString().padStart(2, '0');            
            const endyear = end.getFullYear();
            const endmonth = (end.getMonth() + 1).toString().padStart(2, '0');
            const endday = end.getDate().toString().padStart(2, '0');
            let startstr = startyear+"-"+startmonth+"-"+startday
            let endstr = endyear+"-"+endmonth+"-"+endday
            data.DateSelect.push(startstr)
            data.DateSelect.push(endstr)
            // picker.$emit('pick', [start, end]);
        }
    },
    {
        text: '近15天',
        onClick(picker: any) {
            data.DateSelect = []
            let start = new Date()
            let end = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 15)
            end.setTime(end.getTime() - 3600 * 1000 * 24)
            start.setHours(0, 0, 0)
            end.setHours(23, 59, 59)
            picker.emit('pick',data.DateSelect);
            const startyear = start.getFullYear();
            const startmonth = (start.getMonth() + 1).toString().padStart(2, '0');
            const startday = start.getDate().toString().padStart(2, '0');            
            const endyear = end.getFullYear();
            const endmonth = (end.getMonth() + 1).toString().padStart(2, '0');
            const endday = end.getDate().toString().padStart(2, '0');
            let startstr = startyear+"-"+startmonth+"-"+startday
            let endstr = endyear+"-"+endmonth+"-"+endday
            data.DateSelect.push(startstr)
            data.DateSelect.push(endstr)
            // picker.$emit('pick', [start, end]);
        }
    },
    {
        text: '近30天',
        onClick(picker: any) {
            data.DateSelect = []
            let start = new Date()
            let end = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            end.setTime(end.getTime() - 3600 * 1000 * 24)
            start.setHours(0, 0, 0)
            end.setHours(23, 59, 59)
            picker.emit('pick',data.DateSelect);
            const startyear = start.getFullYear();
            const startmonth = (start.getMonth() + 1).toString().padStart(2, '0');
            const startday = start.getDate().toString().padStart(2, '0');            
            const endyear = end.getFullYear();
            const endmonth = (end.getMonth() + 1).toString().padStart(2, '0');
            const endday = end.getDate().toString().padStart(2, '0');
            let startstr = startyear+"-"+startmonth+"-"+startday
            let endstr = endyear+"-"+endmonth+"-"+endday
            data.DateSelect.push(startstr)
            data.DateSelect.push(endstr)
            // console.log(data.DateSelect);
            // picker.$emit('pick', [start, end]);
        }
    },
    {
        text: '近3月',
        onClick(picker: any) {
            data.DateSelect = []
            let start = new Date()
            let end = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            end.setTime(end.getTime() - 3600 * 1000 * 24)
            start.setHours(0, 0, 0)
            end.setHours(23, 59, 59)
            picker.emit('pick',data.DateSelect);
            const startyear = start.getFullYear();
            const startmonth = (start.getMonth() + 1).toString().padStart(2, '0');
            const startday = start.getDate().toString().padStart(2, '0');            
            const endyear = end.getFullYear();
            const endmonth = (end.getMonth() + 1).toString().padStart(2, '0');
            const endday = end.getDate().toString().padStart(2, '0');
            let startstr = startyear+"-"+startmonth+"-"+startday
            let endstr = endyear+"-"+endmonth+"-"+endday
            data.DateSelect.push(startstr)
            data.DateSelect.push(endstr)
            // picker.$emit('pick', [start, end]);
        }
    },
]

        
    });

    const changeClick = () => {
        return false;
    };

    const clearChangeSpName = () => {      
      data.SpName = ""
      data.SpValue = 0
      data.isSelect = 0;
      // console.log("clearChangeStatus");
    };

    const selectMarketingReport = (item: any) => { 
        // console.log("DateSelect");
        // console.log(data.DateSelect);
      if(data.DateSelect[0] == ""){
        ElMessageBox.alert("请选择开始日期");
        return;
      }
      // if(data.DateSelect == null){
      //   ElMessageBox.alert("请选择开始日期");
      //   return;
      // }
      if(data.DateSelect[0] === undefined){
        ElMessageBox.alert("请选择开始日期");
        return;
      }
      if(data.SpName == ""){
        data.SpValue = ''
      }
      getMarketingReportDay();
      getMarketingReportMonth();
      getMarketingReportAll();
    };
    const getMarketingReportDay = () => {
    
      data.marketingReportDayList = [];
      console.log(data.DateSelect);
      
      let marketingReportdata = {reportType: 0 , packageId: data.SpValue , startDate: data.DateSelect[0], endDate: data.DateSelect[1]}
      console.log(marketingReportdata);
      Api.Config.GetMarketingReportList('XJ',marketingReportdata).then((res: any) => {
        console.log("res");
        console.log(res);
        if (res.Code == 0) {  
            for(var i = 0; i < res.Data.length; i++){ 
                data.marketingReportDayList.push(res.Data[i]);
                // list.push(res.Data.data[i])
            }
        } 
      });
            // console.log(data.complaintFormStatisticsList);
    };

    const getMarketingReportMonth = () => {
    
      data.marketingReportMonthList = [];
      console.log(data.DateSelect);
      let marketingReportdata = {reportType: 1 , packageId: data.SpValue , startDate: data.DateSelect[0], endDate: data.DateSelect[1]}
      console.log(marketingReportdata);
      Api.Config.GetMarketingReportList('XJ',marketingReportdata).then((res: any) => {
        console.log("res2");
        console.log(res);
        if (res.Code == 0) {  
            for(var i = 0; i < res.Data.length; i++){ 
                data.marketingReportMonthList.push(res.Data[i]);
                // list.push(res.Data.data[i])
            }
        } 
      });
    };
    const getMarketingReportAll = () => {
        data.marketingReportAllList = [];
        console.log(data.DateSelect);
        let marketingReportdata = {reportType: 2 , packageId: data.SpValue , startDate: data.DateSelect[0], endDate: data.DateSelect[1]}
        console.log(marketingReportdata);
        Api.Config.GetMarketingReportList('XJ',marketingReportdata).then((res: any) => {
            console.log("res3");
            console.log(res);
            if (res.Code == 0) {  
                for(var i = 0; i < res.Data.length; i++){ 
                    data.marketingReportAllList.push(res.Data[i]);
                    // list.push(res.Data.data[i])
                }
            } 
        });
    };
    const getPackageList = () => {
        data.packageList = [];
        Api.Config.GetPackageList('XJ').then((res: any) => {
            console.log("res");
            console.log(res);
            if (res.Code == 0) {  
                for(var i = 0; i < res.Data.length; i++){ 
                    data.packageList.push(res.Data[i]);
                }
            } 
        });
    };
    const selectSpName = async (item: any) => {        
        await nextTick();
      // console.log(item);
        // console.log(data.isSelect);
      if(data.isSelect > 0){
        data.SpValue = parseInt(item.PackageId);
        data.SpName = item.PackageName;
        // console.log(item);
      }
        data.isSelect = 1;
    };
    onMounted(() => {
        getPackageList();
        
        // console.log(proxy);
    });

    return {
      ...toRefs(data),
      activeName: 'day',
      getMarketingReportDay,
      getMarketingReportMonth,
      getMarketingReportAll,
      selectMarketingReport,
      changeClick,
      selectSpName,
      getPackageList,
      clearChangeSpName,
    };
  },
});
