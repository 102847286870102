
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  computed,
  ref,
} from "vue";
import { Api } from "@/assets/Api";
import { nextTick } from 'vue';

export default defineComponent({
  name: "ConfigRevenueBudget",
  components: {},
  setup() {
    let data = reactive<any>({
      RevenueBudgetList:[],
      OperateValue: 0,
      OperateName:"",
      isSelect: 1,
      RevenueBudgetForm:{
        ModelArea:"",
        ModelName:"",
        PackagesFee:"",
        MarketingFee:"",
        SharingRatio:"",
        BadDebtsRatio:"",
        DelayedMonth:"",
      },
      options: [
        { label: '新疆114适老服务', value: '1' },
        { label: '新疆健康助手', value: '2' },
        { label: '车主权益会员', value: '3' },
      ]
    });
    
    const clearOperateChange = (item: any) => {
      // console.log("label");
      // console.log(item);
      data.OperateName = ""
      data.OperateValue = 0
      data.isSelect = 0;     
    };
    const selectOperateChange = async (item: any) => {
      // console.log("label");
      // console.log(item);
      await nextTick();
      // console.log(item);
        // console.log(data.isSelect);
      if(data.isSelect > 0){
        data.OperateValue = parseInt(item.value);
        // console.log(item);
      }
        data.isSelect = 1;
                
    };

    const getRevenueBudget = (res: any) => {
      data.RevenueBudgetList = [];
        for(var i = 0; i < res.length; i++){
            // res[i].Expenditure = res[i].Expenditure / 100;
            // res[i].Income = res[i].Income / 100;
            // res[i].Profit = res[i].Profit / 100;
            // console.log(res[i].Expenditure / 100);
            data.RevenueBudgetList.push(res[i])
        }
        // console.log("data.RevenueBudgetList");
        // console.log(data.RevenueBudgetList);
    };

    const submitOperate = () => {
        if(data.OperateName == ""){
            Api.Msg.warning("请选择服务名称");
            return;
        } 
        if(data.OperateValue == 1){
            data.RevenueBudgetForm.ModelArea = "XJ";
            data.RevenueBudgetForm.ModelName = "114适老服务";
        }
        else if(data.OperateValue == 2){
            data.RevenueBudgetForm.ModelArea = "XJ";
            data.RevenueBudgetForm.ModelName = "健康助手";
        }
        else if(data.OperateValue == 3){
            data.RevenueBudgetForm.ModelArea = "XJ";
            data.RevenueBudgetForm.ModelName = "车主权益会员";
        }
        data.RevenueBudgetForm.PackagesFee = parseFloat(data.RevenueBudgetForm.PackagesFee);
        data.RevenueBudgetForm.MarketingFee = parseFloat(data.RevenueBudgetForm.MarketingFee);
        data.RevenueBudgetForm.SharingRatio = parseFloat(data.RevenueBudgetForm.SharingRatio);
        data.RevenueBudgetForm.BadDebtsRatio = parseFloat(data.RevenueBudgetForm.BadDebtsRatio);
        data.RevenueBudgetForm.DelayedMonth = parseInt(data.RevenueBudgetForm.DelayedMonth);
        // console.log("data.RevenueBudgetForm");
        console.log(data.RevenueBudgetForm);
        Api.Config.SetRevenueBudget(data.RevenueBudgetForm).then((res: any) => {
        //   console.log("res");
        // console.log(res);
        if (res.Code == 0) {  
            Api.Msg.success("提交完成");
            getRevenueBudget(res.Data);
        } 
      });
        // data.OperateValue = 0;
        // data.OperateName = "";
        // data.RevenueBudgetForm.PackagesFee = "";
        // data.RevenueBudgetForm.MarketingFee = "";
        // data.RevenueBudgetForm.SharingRatio = "";
        // data.RevenueBudgetForm.BadDebtsRatio = "";
        // data.RevenueBudgetForm.DelayedMonth = "";
    };

    // onMounted(() => {
    // });

    return {
      ...toRefs(data),
      getRevenueBudget,
      selectOperateChange,
      clearOperateChange,
      submitOperate,
    };
  },
});
