import { Request } from './Request'
import { ElMessage } from "element-plus";

//登陆
export interface LoginDto {
  UserName: string;
  PassWord: string;
}
//列表
export interface Query {
  limit: number;
  page: number;
  where: any;
  sort: string;
}
//排序
export interface Order {
  key: number;
  steps: number;
}
//菜单
export interface MenuDto {
  id: number;
  menuName: string;
  icon: string;
  path: string;
  fid: number;
  roles: [string];
}
//老后台管理员
export interface AdminDto {
  id: number;
  username: string;
  password: string;
  dba: number;
  hosptials: [{ hospitalid: number }];
}
//登陆
export interface ChangePasswordDTO {
  OldPassword: string;
  NewPassword: string;
}
//地市
export interface CityDTO {
  id: number;
  cityname: string;
  csid: number;
  standid: string;
  Enabled: boolean;
}
//医院
export interface HospitalDTO {
  HospitalID: number;
  HospitalName: string;
  Intro: string;
  cityid: number;
  isguahao: boolean;
  HospitalType: number;
  Addr: string;
  Tel: string;
  Email: string;
  photo: string;
  ts: string;
  hsz: boolean;
  nbzl: string;
  qhdd: string;
  qhsjd: string;
  TRAFFIC: string;
  Rules: string;
  Docking: boolean;
  Enabled: boolean;
  Exam: boolean;
}

//科室
export interface SectionDTO {
  SectionID: number;
  HospitalID: number;
  SectionName: string;
  Intro: string;
  //cityid: number;
  ssid: number;
  hsid: string;
  qhdd: string;
  qhsjd: string;
  zdks: boolean;
  szd: string;
  gzd: boolean;
  Enabled: boolean;
}

//医生
export interface DoctorDTO {
  id: number;
  hospitalid: number;
  doctorname: string;
  sex: string;
  sections: Array<number>;
  Intro: string;
  nbzl: string;
  headship: string;
  good: string;
  xzzw: string;
  csrq: string;
  cynx: string;
  xsxw: string;
  jszc: string;
  jxzc: string;
  gf: boolean;
  tpzj: boolean;
  zdzj: boolean;
  redoc: boolean;
  VIPFee: boolean;
  Enabled: boolean;
}

//直连医生
export interface DocDTO {
  HospitalId: number;
  DocId: number;
  Gender: number;
  DocDescript: string;
  RegisterStatus: number;
  PhotoURL: string;
}

//直连医生
export interface DockingDoctorDTO {
  DocId: number;
  HosId: number;
  RegisterStatus: boolean;
}


//排班
export interface REGDTO {
  id: number;
  doctorid: number;
  sectionid: string;
  online: Array<string>;
  money: number;
  isguahao: boolean;
  hospitalid: number;
  istel: boolean;
  isim: boolean;
  hdid: string;
}

//停诊
export interface StopTreatmentDTO {
  st_start: string;
  st_end: string;
  st_remark: string;
  regs: {
    hospital: number;
    reg: number;
  };
}

//预约规则
export interface RulesDTO {
  id: number;
  typeid: number;
  orgid: string;
  tqdays: number;
  dayopentime: string;
  dayclosetime: string;
  weekstartcloseday: number;
  weekclosetime: string;
  weekclosedays: number;
  status: number;
}

//短信模板
export interface SMSDTO {
  TemplateContent0: string;
  TemplateContent1: string;
  TemplateContent2: string;
  TemplateContent3: string;
}

//时间段
export interface TimeDTO {
  id: number;
  timetype: number;
  typeid: number;
  timestr: Array<string>;
  ghnum: number;
  ghtype: number;
  netgh: boolean;
  netsource: number;
  qhsjd: string;
  qhdd: string;
  cmcc: boolean;
  cmccsource: number;
}

//重新生成号源
export interface ResetSourceDTO {
  timetype: number;
  typeid: number;
  day: number;
}

//修改订单状态
export interface RegListStatusDTO {
  status: number;
  remark: string;
}

//公告
export interface TipDTO {
  TipId: number;
  csId: number;
  cityId: number;
  hospitalId: number;
  departmentId: number;
  Tip: string;
  Status: number;
}

//时间段状态
export interface TimeStatusDTO {
  timetype: number;
  ids: any[];
}


//新疆用户
export interface XjCrmUserDTO {
  id: number;
  phone: string;
  packagesId: number;
  more1: string;
  more2: string;
}

//新疆用户修改订单状态
export interface XjCrmUserStatusDTO {
  phone: number;
  packagesId: number;
  memo: string;
}

//陪诊订单
export interface VisitOrderDto {
  city: string;
  userPhone: string;
  userName: string;
  userIDCard: string;
  userDate: string;
  memo: string;
}

//安徽电信用户查询
export interface AHQueryorderDto {
  phone: string;
}
export interface AHUnsubscribeDto {
  phone: string;
  productCode: string;
}
export interface AHUserPhoneDto {
  userphone: string;
}
export interface AHUserBlackDto {
  userphone: string;
  memo: string;
}

class Api {
  public static Msg = {
    successAlwaysShow: (msg: string) => ElMessage.success(
      {
        duration: 0,
        message: msg,
        showClose: true,
      }
    ),
    success: (msg: string) => ElMessage.success(msg),
    warning: (msg: string) => ElMessage.warning({
      showClose: true,
      duration: 10000,
      message: msg,
    }),
  }

  public static User = {
    Login: (data: LoginDto) => Request.post(`register/Login`, data),
    Config: () => Request.get(`Config`),
    ChangePassword: (data: ChangePasswordDTO) => Request.put(`user/cp`, data),

    GetHelpOption: () => Request.get(`Help/option`),
    GetHelpList: (query: Query) => Request.get(`Help`, query),

  }

  public static Config = {
    GetMenuOption: () => Request.get(`Config/menu/option`),
    GetMenuList: (query: Query) => Request.get(`Config/menu`, query),
    AddMenu: (menu: MenuDto) => Request.post(`Config/menu`, menu),
    EditMenu: (key: string, menu: MenuDto) => Request.put(`Config/menu/${key}`, menu),
    OrderMenu: (order: Order) => Request.put(`Config/menu/order`, order),

    GetCityOption: () => Request.get(`register/city/option`),
    GetCityList: (query: Query) => Request.get(`register/city`, query),
    AddCity: (city: CityDTO) => Request.post(`register/city`, city),
    EditCity: (key: string, city: CityDTO) => Request.put(`register/city/${key}`, city),

    GetPackagesOption: () => Request.get(`xjcrm/packages/option`),
    GetPackagesList: (query: Query) => Request.get(`xjcrm/packages`, query),

    GetPackagesServiceOption: () => Request.get(`xjcrm/PackagesServices/option`),
    GetPackagesServiceList: (query: Query) => Request.get(`xjcrm/PackagesServices`, query),

    GetHospitalOption: () => Request.get(`register/hospital/option`),
    GetHospitalList: (query: Query) => Request.get(`register/hospital`, query),
    AddHospital: (hospital: HospitalDTO) => Request.post(`register/hospital`, hospital),
    EditHospital: (key: string, hospital: HospitalDTO) => Request.put(`register/hospital/${key}`, hospital),
    OrderHospital: (order: Order) => Request.put(`register/hospital/order`, order),
    GetHospitalRules: (key: string) => Request.get(`register/hospital/${key}/rules`),
    GetHospitalSMS: (key: string) => Request.get(`register/hospital/${key}/sms`),

    AddRules: (rules: RulesDTO) => Request.post(`register/rules`, rules),
    EditRules: (key: string, rules: RulesDTO) => Request.put(`register/rules/${key}`, rules),

    EditSMS: (key: string, sms: SMSDTO) => Request.put(`register/sms/${key}`, sms),

    GetSectionListOption: () => Request.get(`register/section/option`),
    GetSectionList: (query: Query) => Request.get(`register/section`, query),
    AddSection: (section: SectionDTO) => Request.post(`register/section`, section),
    EditSection: (key: string, section: SectionDTO) => Request.put(`register/section/${key}`, section),
    OrderSection: (order: Order) => Request.put(`register/section/order`, order),

    GetDoctorListOption: () => Request.get(`register/doctor/option`),
    GetDoctorList: (query: Query) => Request.get(`register/doctor`, query),
    AddDoctor: (doctor: DoctorDTO) => Request.post(`register/doctor`, doctor),
    EditDoctor: (key: string, doctor: DoctorDTO) => Request.put(`register/doctor/${key}`, doctor),

    GetDocListOption: () => Request.get(`register/doc/option`),
    GetDocList: (query: Query) => Request.get(`register/doc`, query),
    EditDoc: (key: string, doctor: DocDTO) => Request.put(`register/doc/${key}`, doctor),
    OrderDoc: (order: Order) => Request.put(`register/doc/order`, order),


    GetREGListOption: () => Request.get(`register/reg/option`),
    GetREGList: (query: Query) => Request.get(`register/reg`, query),
    EditREG: (key: string, reg: REGDTO) => Request.put(`register/reg/${key}`, reg),
    OrderREG: (order: Order) => Request.put(`register/reg/order`, order),

    GetStopTreatmentListOption: () => Request.get(`register/stoptreatment/option`),
    GetStopTreatmentListInfoOption: () => Request.get(`register/stoptreatment/infooption`),
    GetStopTreatmentList: (query: Query) => Request.get(`register/stoptreatment`, query),
    DeleteStopTreatment: (key: string) => Request.delete(`register/stoptreatment/${key}`),
    AddStopTreatment: (stop: StopTreatmentDTO) => Request.post(`register/stoptreatment`, stop),

    GetTodayRegListOption: () => Request.get(`register/reglist/today/option`),
    GetTodayRegList: (query: Query) => Request.get(`register/reglist/today`, query),

    GetTimeListOption: () => Request.get(`register/time/option`),
    GetTimeList: (query: Query) => Request.get(`register/time`, query),
    AddTime: (time: TimeDTO) => Request.post(`register/time`, time),
    EditTime: (key: string, time: TimeDTO) => Request.put(`register/time/${key}`, time),
    DeleteTime: (key: string) => Request.put(`register/time/${key}/delete`, {}),
    OrderTime: (order: Order) => Request.put(`register/time/order`, order),
    GetTimeStatusList: (time: TimeStatusDTO) => Request.post(`register/time/status`, time),

    ResetSource: (reset: ResetSourceDTO) => Request.post(`register/time/resetsource`, reset),

    GetTipListOption: () => Request.get(`register/tip/option`),
    GetTipList: (query: Query) => Request.get(`register/tip`, query),
    AddTip: (tip: TipDTO) => Request.post(`register/tip`, tip),
    EditTip: (key: string, tip: TipDTO) => Request.put(`register/tip/${key}`, tip),
    OrderTip: (order: Order) => Request.put(`register/tip/order`, order),

    GetDockingDoctorListOption: () => Request.get(`register/doctor/docking/option`),
    GetDockingDoctorList: (query: Query) => Request.get(`register/doctor/docking`, query),
    EditDockingDoctor: (key: string, doctor: DockingDoctorDTO) => Request.put(`register/doctor/docking/${key}`, doctor),
    OrderDockingDoctor: (order: Order) => Request.put(`register/doctor/docking/order`, order),

    GetXjCrmUserListOption: () => Request.get(`xjcrm/User/option`),
    GetXjCrmUserList: (query: Query) => Request.get(`xjcrm/User`, query),
    GetXjCrmUserLogListOption: () => Request.get(`xjcrm/User/log/option`),
    GetXjCrmUserLogList: (query: Query) => Request.get(`xjcrm/User/log`, query),
    AddXjCrmUsertiyan: (xjcrmuser: XjCrmUserDTO) => Request.post(`xjcrm/User/trial/activate`, xjcrmuser),
    AddXjCrmUser: (xjcrmuser: XjCrmUserDTO) => Request.post(`xjcrm/User/activate`, xjcrmuser),
    cancelXjCrmUsertiyan: (xjcrmuser: XjCrmUserStatusDTO) => Request.post(`xjcrm/User/trial/cancel`, xjcrmuser),
    cancelXjCrmUser: (xjcrmuser: XjCrmUserStatusDTO) => Request.post(`xjcrm/User/cancel`, xjcrmuser),

    GetTouSuList: (data: any) => Request.get(`xjcrm/User/complaintForm`, data),
    GetComplaintFormLogsList: (data: any) => Request.get(`xjcrm/User/complaintFormLogList`, data),
    addTouSu: (data: any) => Request.post(`xjcrm/User/complaintForm`, data),
    editTouSu: (data: any) => Request.post(`xjcrm/User/updateComplaintForm`, data),
    handleTousu: (data: any) => Request.post(`xjcrm/User/setComplaintForm`, data),
    GetComplaintFormList: (data: any) => Request.get(`xjcrm/User/complaintFormList`, data),

    Uploadimage: (data: any) => Request.imgpost(`/config/upload/complaintForm`, data),

    GetBillingNodeList: (data: any) => Request.get(`xjcrm/User/billingNodeList`, data),
    setBillingNode: (data: any) => Request.post(`xjcrm/User/setBillingNode`, data),

    GetComplaintFormStatisticsList: (data: any) => Request.get(`xjcrm/User/complaintFormStatistics`, data),
    GetComplaintFormStatisticsCountList: (data: any) => Request.get(`xjcrm/User/complaintFormStatisticsCount`, data),

    SetRevenueBudget: (data: any) => Request.get(`Budget/RevenueBudget`, data),
    // cancelXjCrmUser: (xjcrmuser: XjCrmUserStatusDTO) => Request.post(`xjcrm/User/cancel`, xjcrmuser),
    // cancelXjCrmUser: (xjcrmuser: XjCrmUserStatusDTO) => Request.post(`xjcrm/User/cancel`, xjcrmuser),
    GetExchangeList: (query: any) => Request.get('exchange/' + query),
    updateExchange: (query: any) => Request.post('exchange/update/' + query),
    getSelectMarketing: (query: any) => Request.get('/marketing/' + query),

    GetPackageList: (query: any) => Request.get(`marketing/` + query +`/PackageList`),
    GetMarketingReportList: (query: any,data: any) => Request.get(`marketing/` + query +`/MarketingReport`, data),

    GetMarketingSwitchingList: () => Request.get(`marketing/MarketingSwitchingList`),
    AddMarketingSwitching: (data: any) => Request.post(`marketing/MarketingSwitching`, data),

    GetApplicationList: (query: any) => Request.get(`marketing/ApplicationList`, query),
    UpdateApplication: (data: any) => Request.post(`marketing/Application`, data),

  }

  public static Report = {
    SMSAnnualReportByChannel: (year: number) => Request.get(`report/sms/${year}/channel`),
    SMSAnnualReportByClient: (year: number) => Request.get(`report/sms/${year}/client`),
    RegisterAnnualReportByProvince: (year: number) => Request.get(`report/register/${year}/province`),
    RegisterAnnualReportByDocking: (year: number) => Request.get(`report/register/${year}/docking`),
    RegisterReportByHospital: (year: any, month: any) => Request.get(`report/register/${year}/${month}/hospital`),
  }

  public static Register = {
    GetAdminOption: () => Request.get(`register/Admin/option`),
    GetAdminInfoOption: () => Request.get(`register/Admin/infooption`),
    GetAdminList: (query: Query) => Request.get(`register/Admin`, query),
    AddAdmin: (admin: AdminDto) => Request.post(`register/Admin`, admin),
    EditAdmin: (key: string, admin: MenuDto) => Request.put(`register/Admin/${key}`, admin),

    GetRegListOption: () => Request.get(`register/reglist/option`),
    GetRegLogListOption: () => Request.get(`register/reglist/log/option`),
    GetRegList: (query: Query) => Request.get(`register/reglist`, query),
    GetRegLogList: (query: Query) => Request.get(`register/reglist/log`, query),
    DownloadRegList: (query: Query) => Request.get(`register/reglist/download`, query),

    SetRegListStatus: (key: string, status: RegListStatusDTO) => Request.put(`register/reglist/${key}/status`, status),
    // EditTime: (key: string, time: TimeDTO) => Request.put(`register/time/${key}`, time),

  }

  public static Service = {
    GetVisitOrderOption: () => Request.get(`service/VisitOrder/option`),
    GetVisitOrderList: (query: Query) => Request.get(`service/VisitOrder`, query),
    AddVisitOrder: (order: VisitOrderDto) => Request.post(`service/VisitOrder`, order),
    GetVisitLogList: (query: Query) => Request.get(`service/VisitOrder/log`, query),
    GetVisitLogListOption: () => Request.get(`service/VisitOrder/log/option`),
    EditVisitOrder: (key: string, order: VisitOrderDto) => Request.put(`service/VisitOrder/${key}`, order),
    SetOrderStatus: (key: string, status: RegListStatusDTO) => Request.put(`service/VisitOrder/${key}/status`, status),

    GetReportOrderOption: () => Request.get(`service/ReportOrder/option`),
    GetReportOrderList: (query: Query) => Request.get(`service/ReportOrder`, query),
    GetReportOrder: (orderId: number) => Request.get(`service/ReportOrder/order/${orderId}`),
    SetReportOrderStatus: (key: string, status: RegListStatusDTO) => Request.put(`service/ReportOrder/${key}/status`, status),

    GetConsultOrderOption: () => Request.get(`service/ConsultOrder/option`),
    GetConsultOrderList: (query: Query) => Request.get(`service/ConsultOrder`, query),
    GetConsultOrder: (orderId: number) => Request.get(`service/ConsultOrder/order/${orderId}`),
    SetConsultOrderStatus: (key: string, status: RegListStatusDTO) => Request.put(`service/ConsultOrder/${key}/status`, status),
    EditConsultOrder: (key: string, order: VisitOrderDto) => Request.put(`service/ConsultOrder/${key}`, order),
    AddConsultOrder: (order: VisitOrderDto) => Request.post(`service/ConsultOrder`, order),

  }


  public static Api2 = {
    GHGroupList: () => Request.http2("GET", `admin/ghgroup`, ""),
    GHAdminList: (data: any) => Request.http2("POST", `admin/ghuser`, data),
    AddGHAdmin: (data: any) => Request.http2("POST", `admin/addghuser`, data),
    EditGHAdmin: (data: any) => Request.http2("POST", `admin/editghuser`, data),
    NMISMPUser: (data: any) => Request.http2("POST", `nm/admin/ismp/user`, data),
    CityList: (AreaId: string) => Request.http2("GET", `register/city/${AreaId}`, ""),
    HospitalList: (AreaId: string) => Request.http2("GET", `register/${AreaId}/Hospital`, ""),
    DelRoleRange: (data: any) => Request.http2("POST", `admin/delrolerange`, data),
    AddRoleRange: (data: any) => Request.http2("POST", `admin/addrolerange`, data),
    RoleRangeList: (data: any) => Request.http2("POST", `admin/rolerange`, data),
    AdminList: (data: any) => Request.http2("POST", `admin/user`, data),
    AddAdmin: (data: any) => Request.http2("POST", `admin/adduser`, data),
    EditAdmin: (data: any) => Request.http2("POST", `admin/edituser`, data),
    ResetAdminPWD: (data: any) => Request.http2("POST", `admin/resetpassword`, data),
    WXOffi: () => Request.http2("GET", `wx/offi`, ""),
    WXMenu: (AppID: string) => Request.http2("GET", `wx/menu/${AppID}`, ""),
    CreateWXMenu: (AppID: string, data: any) => Request.http2("POST", `wx/menu/${AppID}`, data),
    SMSList: (phone: string) => Request.http2("GET", `sms/list/${phone}`, ""),
    PlanRegTelReport: () => Request.http2("GET", `plan/regtelreportlist`, ""),
    PlanOrderSms: (data: any) => Request.http2("POST", `plan/order/sms`, data),
    PlanEditOrder: (data: any) => Request.http2("POST", `plan/order/edit`, data),
    PlanOrderUser: (tel: any) => Request.http2("GET", `plan/order/user/${tel}`, ""),
    PlanNumberUnlock: (data: any) => Request.http2("POST", `plan/number/unlock`, data),
    PlanAddOrder: (data: any) => Request.http2("POST", `plan/order/add`, data),
    PlanNumberLock: (data: any) => Request.http2("POST", `plan/number/lock`, data),
    PlanNumber: (data: any) => Request.http2("GET", `plan/${data.HospitalId}/${data.SchemeId}/${data.ResDate}/${data.SchemeDaypart}/number`, ""),
    PlanScheme: (data: any) => Request.http2("GET", `plan/${data.HospitalId}/${data.DepId}/${data.DocId}/scheme`, ""),
    PlanHospital: () => Request.http2("GET", `plan/Hospital`, ""),
    PlanDepartment: (data: any) => Request.http2("GET", `plan/${data.HospitalId}/department`, ""),
    HospitalMore: (HospitalId: any) => Request.http2("GET", `register/hmore/${HospitalId}`, ""),
    PlanDepartmentScheme: (data: any) => Request.http2("GET", `plan/${data.HospitalId}/${data.DepId}/scheme`, ""),
    PlanCopyReg: (data: any) => Request.http2("POST", `plan/copyreg`, data),
    PlanOrder: (data: any) => Request.http2("GET", `plan/order/${data}`, ""),
    PlanAdd: (data: any) => Request.http2("POST", `plan/add`, data),
    PlanDoctor: (data: any) => Request.http2("GET", `plan/${data.HospitalId}/${data.DepId}/doctor`, ""),

    RegisterReportByOperator: (data: any) => Request.http2("GET", `plan/regreportlist/${data}`),

    AHUser: (dto: AHQueryorderDto) => Request.http2("POST", `ah/admin/user`, dto),
    AHunsubscribe: (dto: AHUnsubscribeDto) => Request.http2("POST", `ah/admin/unsubscribe`, dto),

    AHselectuserblack: (dto: AHUserPhoneDto) => Request.http2("POST", `ah/admin/selectuserblack`, dto),
    AHdeleteuserblack: (dto: AHUserPhoneDto) => Request.http2("POST", `ah/admin/deleteuserblack`, dto),
    AHadduserblack: (dto: AHUserBlackDto) => Request.http2("POST", `ah/admin/adduserblack`, dto),


  }

}
export {
  Api
}
